<template>
  <div class="news news-page">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="row">
        <div class="col-9 news-content">
          <div class="news-date">
            {{ currentNews.date | moment('D MMMM YYYY') }}
          </div>
          <div class="h4">{{ currentNews.name }}</div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                :key="i"
                class="swiper-slide"
                v-for="(item, i) in currentNews.preview">
                <div class="swiper-slide-background">
                  <img class="swiper-slide-background-image" :src="API_URL + item.formats.thumbnail.url" />
                </div>
                <img class="swiper-slide-image" :src="API_URL + item.url" />
              </div>
            </div>
          </div>
          <Pagination :swiper="newsSwiper" v-if="currentNews.preview.length > 1" />
          <div class="news-text marked-text" v-html="marked(currentNews.content)"></div>
          <video
            v-if="currentNews.video"
            :src="getAssetUrl(currentNews.video.url, true)"
            controls
            disablePictureInPicture
            controlslist="nodownload noplaybackrate" />
        </div>
        <div class="col-3 col-pl">
          <p class="news-other">Другие новости</p>
          <NewsItem class="news-item--wide" v-for="(item, i) in newsList" :key="i" :data="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import { mapState } from 'vuex';
import NewsItem from '@/components/News/NewsItem.vue';
import Pagination from '@/components/Pagination.vue';
import Swiper from 'swiper';
import marked from 'marked';
import Loader from '@/components/Loader.vue';
import { getAssetUrl } from '@/helpers';

export default {
  name: 'NewsID',
  components: { Loader, NewsItem, Pagination },
  data() {
    return {
      newsSwiper: null,
      isLoading: true,
      marked,
    };
  },
  computed: {
    ...mapState({
      currentNews: (state) => state.news.currentNews,
      newsList: (state) => state.news.newsList,
    }),
    API_URL() {
      return config.API_URL;
    },
    newsID() {
      return this.$route.params.id;
    },
  },
  methods: {
    loadData(id) {
      this.$store.dispatch('getNewsById', id).then(() => {
        this.isLoading = false;
        this.$emit('setLastCrumb', this.currentNews.name);
        this.$nextTick(() => {
          this.newsSwiper = new Swiper('.swiper-container', {
            spaceBetween: 100,
          });
        });
      });
    },
    getAssetUrl(data) {
      return getAssetUrl(data);
    },
    loadNews() {
      this.$store.dispatch('getNewsList', { limit: 4 });
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadNews();
    this.loadData(this.newsID);
  },
  beforeRouteUpdate(to, from, next) {
    this.isLoading = true;
    this.loadData(to.params.id);
    next();
  },
};
</script>
