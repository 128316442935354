<template>
  <router-link class="news-item" :to="`/news/${data.id}`" :key="data.id">
    <p class="news-item__date">{{ data.date | moment('D MMMM YYYY') }}</p>
    <div class="news-item__preview">
      <div class="news-item__preview-background">
        <img class="news-item__preview-background-image" v-lazyload="backgroundPreviewUrl" />
      </div>
      <img class="news-item__preview-image" v-lazyload="largePreviewUrl" />
    </div>
    <p class="news-item__text" v-html="data.name"></p>
    <p class="news-item__content" v-html="data.content"></p>
  </router-link>
</template>

<script>
import { getPreviewUrl } from '@/helpers';
import '@/assets/sass/news.sass';

export default {
  name: 'NewsItem',
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    largePreviewUrl() {
      return getPreviewUrl(this.data.preview[0], true);
    },
    backgroundPreviewUrl() {
      return getPreviewUrl(this.data.preview[0], false);
    },
  },
};
</script>
